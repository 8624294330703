
body {
  margin: 0;
  font: Andalé Mono;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto','Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  margin: 0;
  width: 100%;
}

.title{
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.welcome {
height: 60vh; 
width: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("header/cover-kuih.jpeg");
  padding-top: 200px;
  padding-bottom: 200px;
}

.welcome img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

 .welcome div{
  width: 100%;
  top: 20%;
  left: 50%;
  
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.4);
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.welcome h1{
  font-size: 5em;
  margin: 2em;
  margin-bottom: 5px;
  white-space: nowrap;
}

.welcome h2{
  font-size: 30px;
  margin-top: 5px;
  white-space: nowrap;
}

.welcome h3{
  font-size: 30px;
  margin-top: 5px;
  color: red;
  white-space: nowrap;
}


.menuContainer{
  justify-content: center;
  display: flex;
  align-items: center;
}

.menuContainer .row{
  min-width: 20rem;
  margin-top: 2px;
  margin-bottom: 10px;
  /* border-bottom: 1px solid black; */
}

.menuContainer .card-body{
  justify-content: center;
}

.kuih-card.card{
  margin: 2px;
  width: 20rem;
  border: unset;


}

.kuih-card.card .card-title{
  font-size: 2rem;
}

.kuih-card.card img:hover {
  opacity: 0.3;
  cursor: pointer;
}

.kuih-card.card.disabled img {

  cursor: not-allowed;
  justify-content: center;
}

.kuih-card.card.disabled img:hover {
  opacity: 1;
  cursor: not-allowed;
}

.kuih-card.card img {
  width: 20rem;
  height: 25rem;
  border: 1px solid black;
  object-fit: cover;
  /* border-radius: 70%; */
}

.modal-dialog{

}

.kuih-display.active{
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

}

.modal-content img{
  width: 30rem;
  height: 30rem;
  border: 1px solid black;
  margin: 3px;
  object-fit: cover;
}

.menuContainer h1{
  padding: 1rem;
}

.contact {
  background-color: lightslategray;
  margin: 0px;
  padding: 0px;
  width: 100%;
  left: 0;
  width: 100%;
  width: 100vw;
}

.contact .form  {
  margin: 5px;
  padding: 5px;
  clear:both; display:inline-block;
  width: 80%;
}

.contact label  {
  float: left;
  clear: both;
  margin-right: 5px
}

.contact input  {
  float: right;
  clear: both;
  width: 100%;
  margin-left: 5px
}

.contact textarea  {
  float: right;
  clear: both;
  width: 100%;
  height: 10em;
  margin-left: 5px;
  resize: none;
}


.contact button  {
  margin: 10px
}

.contact h1{
  padding-top: 5px;
}

.contact .social-media  {

  margin-top: 20px;
  padding: 5px;

}

.contact .social-media a {
  padding-left: 3px;
  padding-right: 3px;
}

.footer{
 justify-content: center;
 text-align: center;
 margin-top: 5px;
 padding-top: 5px;
 margin-bottom: 5px;
 padding-bottom: 5px;
}

.social-media a{
  padding-right: 5px;
}

.shopping-basket{
  color: plum;
}

.faq{
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  padding-bottom: 5em;
  width: 100%;
  left: 0;
  width: 100%;
  width: 100vw;
}

.faq h1{
  color: black;
}