.header {
    background-image: url('./assorted-kuih-box.jpg'); 
    background-size: cover;
    height: 100%;
    background-size : cover;
    padding-top: 25px;
    padding-bottom: 200px;
    overflow: hidden;
  }

  .welcomeBanner {
    /* top: 90%; */
    /* left: 50%;
    transform: translate(-50%, -50%); */
    color: white;
    /* text-align: center; */
    font-family: Impact, Charcoal, sans-serif;
    /* vertical-align: top; */
    font-size: 100px;
  }


  .bar {

    position: relative;
      padding: 20px;
      width: auto;
      max-height: 50px;
      background-color: black;
      border-style: solid;
      vertical-align: bottom;

      display: flex;  
      justify-content: space-between;
  }

  .subBanner{
    color: white;
    font-family: Impact, Charcoal, sans-serif;
    text-align: left;
    font-size: 50px;

    max-width: 400px;
    word-wrap: break-word;
  }

  bar.socialMedia{
    text-align: right
  }