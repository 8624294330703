

.menuItem {
    padding: 4rem 2rem;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    display: grid;
    align-items: center;
}

.menuItem > h1 {
    font-size: 4rem;
    padding-bottom: 1rem;
}

/* breweries styles */
.menuContainer {
    padding: 2rem;
  }
  
  .menuContainer > ol {
    display: grid;
    /* grid-template-columns: 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: .5rem;
  }
  
  .menuContainer > ol > li {
    border-radius: .5rem;
    list-style-type: none;
  }

  .menuItem > h3 {
    position: relative;
    font-size: 32px;
    width: max-content;
    
  }

  .menuItem > figure {
    max-height: 500px;
    min-width: 250px;
    overflow: hidden;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    position: relative;
  }

  .menuItem > figure > img {
    width: 100%;
  }
  
  .menuItem > p {
    font-size: 1rem;
    line-height: 1.5;
    padding: 1rem .75rem;
    color: #666666;
  }